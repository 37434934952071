import { useRestaurantContext } from '@/contexts/restaurant';
import { useTranslation } from '@/hooks/translations';
import { WhatsappIcon } from '@/icons/svgIcons';
import { Box, Button, useMediaQuery, useTheme } from '@mui/material';

export default function WhatsappChatButton() {
    const { restaurant } = useRestaurantContext();
    const { t } = useTranslation('common');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(800));

    if (restaurant?.restaurant_country?.config?.whatsappHelpNumber) {
        return (
            <Box sx={{ backgroundColor: 'red' }}>
                <Button
                    sx={{
                        position: 'fixed',
                        bottom: isMobile ? '1rem' : '2rem',
                        left: isMobile ? '10px' : '100px',
                        zIndex: 10000,
                        color: 'white',
                        backgroundColor: '#25d366',
                        '&:hover': {
                            backgroundColor: 'black',
                        },
                        borderRadius: '15px',
                        paddingX: '1rem',
                        paddingY: '0.5rem',
                    }}
                    startIcon={<WhatsappIcon />}
                    onClick={() =>
                        window.open(
                            `https://wa.me/${
                                restaurant?.restaurant_country?.config?.whatsappHelpNumber
                            }?text=Restaurant%20Unique:%20${restaurant?.name || restaurant?.restaurant_unique || ''}`,
                            '_blank',
                        )
                    }
                >
                    {t('Support')}
                </Button>
            </Box>
        );
    }
    return null;
}
