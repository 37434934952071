import { useEffect, useState } from 'react';
import { Box, IconButton, Typography, Popover } from '@mui/material';
import { CheckCircleOutline, ContentCopyRounded } from '@mui/icons-material';
import clipboard from 'clipboardy';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from '@/hooks/translations';

const CopyToClipboard = ({
    value,
    size = 'medium',
    color,
    innerText,
}: {
    value?: string | number;
    size?: 'large' | 'medium' | 'small';
    color?: string;
    innerText?: string;
}) => {
    const { t } = useTranslation('common');
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (anchorEl) {
            timeout = setTimeout(handleClose, 3000);
        }

        return () => {
            if (anchorEl) {
                clearTimeout(timeout);
            }
        };
    }, [anchorEl]);

    const open = Boolean(anchorEl);
    const id = open ? 'copied-successfully-text-popover' : undefined;
    const popoverValue = typeof value === 'string' ? (value?.length < 40 ? value : '') : value;

    if (value && ((typeof value === 'string' && value.length > 0) || typeof value !== 'string')) {
        return (
            <>
                <IconButton
                    aria-describedby={id}
                    edge="start"
                    // color="default"
                    onClick={(e) => {
                        handleClick(e);
                        clipboard.write(String(value)).catch(console.warn);
                    }}
                    sx={{
                        color: color || theme.palette.info.light,
                        ':hover': {
                            color: color ?? theme.palette.info.dark,
                        },
                        transition: '0.2s ease-in-out',
                        padding: '4px',
                        margin: 0,
                    }}
                    size={size}
                >
                    <ContentCopyRounded fontSize={size} />
                    {innerText && (
                        <Typography
                            sx={{
                                fontFamily: 'Montserrat',
                                fontSize: '14px !important',
                                marginLeft: '0.5rem',
                            }}
                        >
                            {innerText}
                        </Typography>
                    )}
                </IconButton>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    sx={{
                        marginBlockStart: -1,
                    }}
                    elevation={8}
                >
                    <Box sx={{ padding: 1.5 }}>
                        <Typography
                            variant="subtitle2"
                            sx={{
                                color: theme.palette.success.main,
                            }}
                        >
                            <CheckCircleOutline fontSize="small" sx={{ marginBlockEnd: -0.6, marginInlineEnd: 1 }} />
                            {popoverValue}
                            {popoverValue ? ' copied to clipboard' : 'Copied to clipboard'}
                        </Typography>
                    </Box>
                </Popover>
            </>
        );
    }
    return null;
};

export default CopyToClipboard;
