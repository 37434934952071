import { useMemo } from 'react';
import { IQsrOrder, OrderStatusEnum } from '@/views/QsrOrders/types';
import { getDateAndTime, getRelativeTime } from '@/services/utils/k_time';
import { Tooltip } from '@mui/material';
import { UpdateRounded } from '@mui/icons-material';
import { useRestaurantContext } from '@/contexts/restaurant';
import { DatePresentationEnum, OrderPaymentMethodEnum, RestaurantOrderMode } from '@/views/OrdersTableView/types';

import styles from './index.module.scss';

interface IProps {
    order: IQsrOrder;
}

const QsrDate = ({ order }: IProps) => {
    const { restaurant } = useRestaurantContext();
    const [date, relativeDate] = useMemo(() => {
        switch (order.orderStatus?.name) {
            case OrderStatusEnum.Scheduled:
                return [getDateAndTime(order.pickupAt), getRelativeTime(order.pickupAt)];
            default:
                if (restaurant?.order_config?.paymentMethod === OrderPaymentMethodEnum.Default) {
                    return [
                        getDateAndTime(order.paidAt || order.createdAt),
                        getRelativeTime(order.paidAt || order.createdAt),
                    ];
                }
                // eslint-disable-next-line no-case-declarations
                const d = order.batchId ? order.date || order.createdAt : order.createdAt;
                return [getDateAndTime(d), getRelativeTime(d)];
        }
    }, [order.orderStatus?.name || '']);

    const getDate = () => {
        if (restaurant?.order_config?.vendorDatePresentation === DatePresentationEnum.Full) {
            return date;
        }
        return relativeDate;
    };

    return (
        <Tooltip title={date}>
            <div className={styles.container}>
                {order.orderStatus?.name === OrderStatusEnum.Scheduled && (
                    <div className={styles.icon}>
                        <UpdateRounded />
                    </div>
                )}
                <div className={styles.date}>{getDate()}</div>
            </div>
        </Tooltip>
    );
};

export default QsrDate;
