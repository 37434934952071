import { useTranslation as useTranslationStatic } from 'next-export-i18n';
import { TFunction } from 'next-i18next';

export const useTranslation = (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ns?: string,
): {
    t: TFunction;
} => {
    const { t } = useTranslationStatic();

    return {
        t,
    };
};
