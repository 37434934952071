import { useCallback, useMemo, useState } from 'react';
import { IQsrOrder, OrderItemStatusEnum, OrderStatusEnum } from '@/views/QsrOrders/types';
import { useTranslation } from '@/hooks/translations';
import { Button } from '@mui/material';
import classNames from 'classnames';
import QsrOrdersService from '@/services/qsrOrders';
import { IRestaurant } from '@/views/OrdersTableView/types';
import { useSnackbar } from 'notistack';

import styles from './index.module.scss';

interface IAction {
    title: string;
    status: OrderItemStatusEnum;
}

export interface IOrderItemStatus {
    id: string;
    uid: string;
    status: OrderItemStatusEnum;
}

interface IProps {
    order: IQsrOrder;
    restaurant: IRestaurant | null;
    onChange: (payload: IOrderItemStatus) => void;
    disabled?: boolean;
}

const QsrOrderAction = ({ order, restaurant, onChange, disabled }: IProps) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const qsrOrdersService = QsrOrdersService.getInstance();
    const [loading, setLoading] = useState(false);

    const orderStatusChangeHandler = (status: OrderItemStatusEnum) => {
        if (loading || !order) {
            return;
        }

        setLoading(true);
        qsrOrdersService
            .setQsrOrderItemStatus({
                restaurantId: restaurant?.id || '',
                orderId: order.id,
                uid: order.batchId || '',
                status,
            })
            .then(() => {
                enqueueSnackbar(t('Order status updated successfully!'), {
                    variant: 'success',
                });
                onChange({
                    id: order.id,
                    uid: order.batchId || '',
                    status,
                });
            })
            .catch((err) => {
                enqueueSnackbar(err?.message || t('Something went wrong!'), {
                    variant: 'error',
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const actions = useMemo<IAction[]>(() => {
        switch (order.batchStatus) {
            case OrderItemStatusEnum.New:
                return [
                    {
                        title: t('Approve'),
                        status: OrderItemStatusEnum.Approved,
                    },
                    {
                        title: t('Reject'),
                        status: OrderItemStatusEnum.Rejected,
                    },
                ];
            case OrderItemStatusEnum.Approved:
                return [
                    {
                        title: t('Close'),
                        status: OrderItemStatusEnum.Completed,
                    },
                    {
                        title: t('Reject'),
                        status: OrderItemStatusEnum.Rejected,
                    },
                ];
            case OrderItemStatusEnum.Scheduled:
                return [
                    {
                        title: t('Reject'),
                        status: OrderItemStatusEnum.Rejected,
                    },
                ];
            default:
            case OrderItemStatusEnum.Completed:
            case OrderItemStatusEnum.Rejected:
                return [];
        }
    }, [order.batchStatus]);

    const getStyle = useCallback((status) => {
        switch (status) {
            default:
            case OrderItemStatusEnum.New:
                return styles.new;
            case OrderItemStatusEnum.Approved:
                return styles.approved;
            case OrderItemStatusEnum.Completed:
                return styles.completed;
            case OrderItemStatusEnum.Rejected:
                return styles.rejected;
        }
    }, []);

    return (
        <div className={styles.container}>
            {actions.map((action) => {
                return (
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={disabled || loading}
                        className={classNames(styles.button, getStyle(action.status))}
                        onClick={() => {
                            orderStatusChangeHandler(action.status);
                        }}
                    >
                        {action.title}
                    </Button>
                );
            })}
        </div>
    );
};

export default QsrOrderAction;
