import { CSSProperties, FC } from 'react';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseRounded from '@mui/icons-material/CloseRounded';

import { useTranslation } from '@/hooks/translations';
import { PrimaryButton } from '@/components/common';

const confirmButton: CSSProperties = {
    width: '15rem',
    borderRadius: '100px',
    margin: '1rem',
};

interface IProps {
    title?: string;
    onClose: () => void;
    onConfirm?: () => void;
    primaryButtonText?: string;
}

const CommonModal: FC<IProps> = ({ title, onConfirm, onClose, primaryButtonText, children }) => {
    const { t } = useTranslation('common');
    return (
        <>
            <DialogTitle
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: title ? 'space-between' : 'flex-end',
                    paddingBlockEnd: 0.5,
                }}
            >
                {title}
                <IconButton onClick={onClose} size="large">
                    <CloseRounded />
                </IconButton>
            </DialogTitle>
            <DialogContent>{children}</DialogContent>
            {onConfirm && (
                <DialogActions>
                    <PrimaryButton
                        text={primaryButtonText || t('Confirm')}
                        styles={confirmButton}
                        onClick={onConfirm}
                    />
                </DialogActions>
            )}
        </>
    );
};

export default CommonModal;
