import { useMemo } from 'react';
import { useLanguageQuery } from 'next-export-i18n';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { enUS, jaJP, ptBR, arSD } from '@mui/material/locale';
import createEmotionRtlCache from '@/theme/cache-rtl';
import createEmotionCache from '@/theme/cache';
import { CacheProvider } from '@emotion/react';

const isRTL = (lang: string) => {
    switch (lang) {
        case 'ar':
            return true;
        default:
            return false;
    }
};

const CustomThemeProvider = ({ children }: any) => {
    const [query] = useLanguageQuery();
    const lang = query ? query.lang : 'en';

    const [cache, theme] = useMemo(() => {
        const rtl = isRTL(lang);
        const local = () => {
            switch (lang) {
                case 'ja':
                    return jaJP;
                case 'pt':
                    return ptBR;
                case 'ar':
                    return arSD;
                default:
                    return enUS;
            }
        };
        if (process.browser) {
            const el = document?.querySelector('html');
            if (el) {
                el.setAttribute('dir', rtl ? 'rtl' : 'ltr');
            }
            const elBody = document?.querySelector('body');
            if (elBody) {
                elBody.setAttribute('dir', rtl ? 'rtl' : 'ltr');
            }
        }
        const getCache = () => {
            if (rtl) {
                return createEmotionRtlCache();
            }
            return createEmotionCache();
        };
        const getTheme = () => {
            return createTheme(
                {
                    direction: rtl ? 'rtl' : 'ltr',
                    palette: {
                        primary: {
                            main: '#7D00D4',
                            light: '#EED7FF',
                        },
                    },
                    typography: {
                        fontFamily: 'Montserrat',
                        fontWeightLight: 300,
                        fontWeightRegular: 400,
                        fontWeightMedium: 500,
                        fontWeightBold: 700,
                    },
                },
                local(),
            );
        };
        return [getCache(), getTheme()];
    }, [lang]);

    return (
        <CacheProvider value={cache}>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </CacheProvider>
    );
};

export default CustomThemeProvider;

export const grays = {
    gray: '#f8f8f8',
    primary: '#EED7FF',
    main: '#F6F6F6',
    dark: '#A9A9A9',
};
