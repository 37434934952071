import { styled } from '@mui/material/styles';
import { useLanguage } from '@/constants/translations';
import { onPushEvent } from '@/services/lib/gtm';
import { updateQueryStringParameter } from '@/services/utils/k_url';
import { isMobile } from '@/services/utils/k_user_agent';
import { useLanguageQuery, useTranslation } from 'next-export-i18n';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { MenuItem, Select } from '@mui/material';

const CustomSelect = styled('select')({
    padding: '6px',
    borderRadius: '8px',
    width: '100%',
});

export default function LanguageSelector() {
    const { t } = useTranslation('common');
    const router = useRouter();
    const [query] = useLanguageQuery();
    const { languages } = useLanguage();

    const native = useMemo(() => isMobile(), []);
    const lang = query ? query.lang : 'en';

    const langChangeHandler = (e: any) => {
        onPushEvent(`Language Switched to ${e.target.value}`);
        router.push(updateQueryStringParameter(router.asPath, 'lang', e.target.value));
    };

    return native ? (
        <CustomSelect value={lang} onChange={langChangeHandler}>
            {languages.map(({ value, title }) => {
                return (
                    <option key={value} value={value}>
                        {title}
                    </option>
                );
            })}
        </CustomSelect>
    ) : (
        <Select
            placeholder={t('Language')}
            fullWidth
            margin="none"
            size="small"
            value={lang}
            onChange={langChangeHandler}
        >
            {languages.map(({ value, title }) => {
                return (
                    <MenuItem key={value} value={value}>
                        {title}
                    </MenuItem>
                );
            })}
        </Select>
    );
}
